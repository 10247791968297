import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";

class BestTime extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                     <Helmet>
                        <title>Best Time for Roofing Repairs or Replacements | Roof-ER</title>
                        <meta name="description" content="Discover the best time of the year to have roofing repairs and replacements completed. Contact us today for a complimentary roof inspection!"/>
                    </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content">
                        <div className="blog-item-container">
                            <div className="blog-item-info-container">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                                <div className="blog-item-info-container-controls">
                                    <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                    <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                                </div>
                                <h1>When Is the Best Time for Roofing Repairs or Replacements?</h1>
                                <p className="main-text">When it comes time to install a new roof, you likely know that you need to pick the roofing contractor with the skills to get the job done. But did you know there is also an ideal time of year to get your roofing project completed &mdash; and when most roofing companies are at their busiest?&nbsp;</p>
                                <h2>Is Roofing a Seasonal Business?</h2>
                                <p>In parts of the country that experience four seasons, such as Virginia's Fairfax County, roofers definitely have a seasonal rhythm. Though you might expect spring to be the most popular time for roofing installation, late summer and fall are much busier.</p>
                                <p>What makes back-to-school time so prime for roofers? Generally speaking, the weather and temperatures offer just the right atmosphere. In the fall, the cooler days make working on roofs less difficult for workers, yet the season's average temperature is still warm enough for asphalt shingles to properly seal.</p>
                                <h2>Can I Hire a Roofer During a Non-Peak Season?</h2>
                                <p>Just because roofers tend to install roofs later in the year doesn't mean they don't work year-round. On the contrary, these professionals appreciate being able to show off their skills and help customers get roof replacements throughout all four seasons.</p>
                                <p>That said, winter roof installations may take longer to finish because of cold breezes and snow, sleet or ice. If you need a roofer in the winter, you can likely get an estimate quickly. However, they may recommend holding off on the project for a couple of weeks, depending on the weather.&nbsp;</p>
                                <h2>Tips for Scheduling Routine Roofing Services</h2>
                                <p>Roofing emergencies can happen throughout the year, and you should feel comfortable calling a roofer immediately when they do.&nbsp;It is likely that a roofer will come to you quickly to fix the damage.</p>
                                <p>When it comes to roofing services unrelated to emergencies, though, you can take a few steps to properly budget your time and money. Remember to:</p>
                                <ul>
                                    <li><strong>Plan ahead:</strong>&nbsp;Instead of waiting until your old roof is in dire need of repair, contact a roofer for a free inspection now. Even if you don't think you'll replace your roof for several months or a year, you can&nbsp;<a title="" href="/faqs" target="get ahead of roofing problems">get a head start on the process</a>.</li>
                                    <li><strong>Get on a roofer's calendar early:&nbsp;</strong>Do you know that you'll want a roofer to work on your home this fall rather than during the slow season? Contact the roofer in the spring or early summer so that you can get your preferred dates or weeks.</li>
                                    <li><strong>Talk about roof materials before the installation:&nbsp;</strong>Sometimes, the types of roofing materials you need will play a part in when your roof install can take place. As noted before, asphalt shingles need time to seal in the heat of the sun. Knowing your preferred materials will help determine when the best time for your roof work is.</li>
                                    <li><strong>Ask about off-season discounts:&nbsp;</strong>Roofers sometimes offer special discounts on off-season work. Feel free to ask about this possibility when you contact the roofing contractors in your area for bids.</li>
                                </ul>
                                <h2>Scheduling a New Roof or Roof Repairs</h2>
                                <p>Your roof is an essential part of your home. It protects everything inside, so make sure you take roof replacement seriously.&nbsp;If you know that you'll soon need a new roof installed or roof replacement in Vienna, VA, and throughout Fairfax County, contact Roof-ER for a&nbsp;<a title="" href="/inspection" target="free inspection of your roof">free inspection of your roof</a>. Roof-ER is the top roof repair and replacement company in Fairfax County.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BestTime;