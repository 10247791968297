import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import FreeQuote from "./custom/freeQuoteBanner";

// Styling
import '../style/components/meetTheDocs.scss';

// Images
import Mike from '../resources/images/employees/MikeHarvey.png';
import ChrisDavis from '../resources/images/employees/ChrisDavis.png';
import Oliver from '../resources/images/employees/OliverBrown.png';

import Rees from '../resources/images/employees/ReesNalley.png';
import GregCampbell from '../resources/images/employees/GregCampbell.png';
import AustinStephens from '../resources/images/employees/AustinStephens.png';
import MattRay from '../resources/images/employees/MattRay.png';
import FordBarsi from '../resources/images/employees/FordBarsi.png';
import PatrickRobertson from '../resources/images/employees/PatrickRobertson.png';
import MaryLombardi from '../resources/images/employees/MaryLombardi.png';
import LuisEsteves from '../resources/images/employees/LuisEsteves.png';
import IsmaelCoreas from '../resources/images/employees/IsmaelCoreas.png';

import JoeMac from '../resources/images/employees/JoeMac.png';
import DanielKeene from '../resources/images/employees/DanielKeene.png';
import JamesMcCarty from '../resources/images/employees/JamesMcCarty.png';



import MikeMac from '../resources/images/employees/MikeMac.png';
import HunterArnold from '../resources/images/employees/HunterArnold.png';
import JimmySnyder from  '../resources/images/employees/JimmySnyder.png';
import AramGhanbari from  '../resources/images/employees/AramGhanbari.png';

import EthanDsouza from '../resources/images/employees/EthanDsouza.png';
import ZachMyers from '../resources/images/employees/ZachMyers.png';
import MikeRafter from '../resources/images/employees/MikeRafter.png';
import BryamGodoy from '../resources/images/employees/BryamGodoy.png';
import KyleGott from '../resources/images/employees/KyleGott.png';
import JesseBrouhard from '../resources/images/employees/JesseBrouhard.png';
import CarlosDavila from '../resources/images/employees/CarlosDavila.png';
import PatrickOHara from '../resources/images/employees/PatrickO\'Hara.png';
import ZacNewdick from '../resources/images/employees/ZacNewdick.png';
import DaleArbaugh from '../resources/images/employees/DaleArbaugh.png';


// import RyanFerguson from '../resources/images/employees/RyanFerguson.png';
// import TimBlackwell from '../resources/images/employees/TimBlackwell.png';
// import Matt from '../resources/images/employees/MattFoster.png';
// import ChaseStrait from '../resources/images/employees/ChaseStrait.png';
// import CormacForan from '../resources/images/employees/CormacForan.png';
// import DaveLaGarde from '../resources/images/employees/DaveLaGarde.png';


import BANNER_ICON_UP from "../resources/images/misc/plus_icon_up.png";
import EXPAND_ICON from "../resources/images/misc/icon_plusred.png";
import COLLAPSE_ICON from "../resources/images/misc/icon_closered.png";

const MIKE_BIO = 'Michael’s very first job was roofing at age 15. From there, he went on to receive a B.A. in International Affairs and an MBA in finance, only to end up right back ' +
    'where it all started. He’s had almost enough schooling to be an M.D., but opted for his R.D. (Roof Doctorate) instead. Michael believes if you approach every endeavor with integrity, ' +
    'are uncompromising on quality, and remain committed to simplicity, success will abound. (Michael is also a washed-up former college athlete. If you have a basketball hoop, he’ll be ' +
    'glad to show you)';
const OLIVER_BIO = 'Oliver once spent a summer loading shingles onto roofs and is very happy to have moved up the ladder in the roofing industry. Since that summer, he has earned his B.S. in ' +
    'Business Marketing & MBA from Mount St. Mary’s University in 2013 & 2015, respectively. Oliver is a passionate sports fan, dog owner, and food connoisseur who believes anything is ' +
    'possible with a gritty, positive mindset. (Oliver is a less "washed-up” college basketball player than Michael and would love to prove that in anyone\'s driveway.)';

const REES_BIO = 'Rees was introduced to the home remodeling industry just out of college when he worked as a site supervisor for a family member’s construction company. While there, he ' +
    'discovered a deep satisfaction for improving the quality of people’s homes and decided to make it his career. Before his work there, Rees graduated from Mount St. Mary’s University ' +
    'where he achieved a B.A. in History and played four years of rugby as a Mountaineer. When Rees isn’t on the job he’s in the gym, on a boat, or on the rugby pitch.';
const CHRIS_BIO = 'Chris is not the ideal candidate to inspect a roof, which is why he\'s kept behind a desk.  Chris has a bachelor\'s degree in Computer Science, and is in charge of software development with The Roof ' +
    'Docs.  He enjoys streamlining business processes through software, which helps the Docs when they\'re out in the field.  He\'s slowly learning the industry, who knows, maybe one day he\'ll be qualified enough ' +
    'to help with your inspection';
const GREG_BIO = 'Greg went to Shepherd University before moving to California in 1994 where he entered the roofing industry by delivering material to roof tops all over the SoCal area. Greg came back to ' +
    'the DMV area, played Semi-Pro football, and has worked for roofing suppliers and installers for the past 20+ years. As our production manager Greg has a strong belief that the customer should receive ' +
    'comprehensive service on each and every job. Greg and his wife Jenn have 2 boys in elementary school and enjoy watching them play sports.';

const AUSTIN_BIO = 'Austin has been interested in building since a young age, which led him to study Engineering Technology at ' +
    'Lincoln Tech in Columbia, MD.  He applied what he learned there to help with his father’s building company in southern ' +
    'Maryland until he decided to venture into home remodeling. He’s an expert in all things residential exterior and he’d love to ' +
    'make a deal with you in the driveway or on the golf course.';

const MATT_RAY_BIO = 'Through the years Matt has always found himself in a customer service driven position. From various retail positions in High School, onto managing client focused projects ' +
    'for exterior remodelers over the past 10 years after college. Matt has always liked being in the DC metro region, and in keeping with that, attended George Mason University where he received his ' +
    'bachelor’s degree in Criminal Justice as well as played 3 seasons with GMU’s ice hockey team. Matt now spends his free time chasing after his two and a half kids as well as jumping into any ' +
    'hockey game he can gets his hands on. To date, none of the other roof docs have had the courage to lace up some skates.'
const FORD_BIO = 'Ford was born and raised in Tampa, Florida where he developed an unhealthy obsession with the Tampa Bay Buccaneers ' +
    'and life on the water. He graduated from the University of Florida in 2009 with a degree in Sociology, but his passion for food ' +
    'ultimately led him to New York City, where he became an executive chef. His food journey took him to Washington, DC to lead ' +
    'the opening of the largest restaurant in the city. He cut his teeth in the wine business next, selling to some of the best hotels ' +
    'and restaurants in our nation’s capital. Through his many journeys he’s taken with him a sincere passion for hospitality and a ' +
    'genuine desire to help others. He is a self-proclaimed sports junkie, DIY home renovator, and the humble father of a three year ' +
    'old princess. ';
const PATRICK_BIO = 'Patrick is originally from England and recently joined the team after living in Boston, MA for the past few years. ' +
    'He spent some summers working for his friend’s family roofing company in the UK before heading to the US as a soccer recruiter ' +
    'after graduating from the University of Essex. Despite his passion for Soccer, his playing career didn’t quite go as planned which ' +
    'is why you might see him in a neighbourhood near you and not on TV on Saturday mornings playing for Manchester United.';
const MARY_BIO = 'Mary grew up in Colorado and has been involved in a couple different business jobs. She has spent time as a project coordinator ' +
    'intern and graduate assistant to the professors and Dean of the business school at Mount St. Mary’s in MD. She has earned her B.S. in Business ' +
    'Administration & MBA from Mount St. Mary’s University in 2019 & 2020, respectively. She is not the best person for the job when it comes to inspecting ' +
    'and selling roofs, which is why we have her behind the scenes as our executive assistant. She hopes to leave an impact here at Roof-ER as she is the only female on our team. ' +
    '(Mary was also a former college athlete. She was a swimmer all four years at the Mount and is quite literally “washed-up” as she is now a fish out of water). ';
const LUIS_ESTEVES_BIO = 'Born and raised in Rotorua, New Zealand, Luis moved to the States to play college soccer at St. John\'s University where he graduated with a B.S. in International Business and ' +
    'Management Degree. His passion for soccer has transitioned to the professional world. Where previously he assisted medical doctors with new surgical devices, he now takes pride in being a doc himself - ' +
    'Roof Doc that is. In his free time, he enjoys the outdoors, either hiking mountains or playing two touch at the beach with family and friends';
const ISMAEL_COREAS_BIO = 'Ismael has been in the construction industry for the past 20 years. He started as a punch-out specialist working on new residential homes. He soon climbed up the ladder and ' +
    'became a rough frame carpenter. During all his time spent in the field, he’s acquired many different skills - from roofing installation and repairs to windows and doors - and has developed a penchant ' +
    'for high-quality work and attention to detail. You’ll meet him at the end of your project, making sure the install has been performed with the quality and care he’s practiced over the last 20 years';
const JOE_MAC_BIO = 'Joe grew up in Annapolis MD and moved to Buffalo where he became a die-hard Bills fan. ' +
    'While still an active member of the Bills Mafia, he has since moved back to the Davidsonville area. He ' +
    'has 3 years of experience within the home remodeling industry and recently graduated from Salisbury ' +
    'University with a business management degree, where he also captained the men\'s rugby team. His ' +
    'favorite part of the job is interacting face-to-face with clients and knowing he has not just met, ' +
    'but exceeded their expectations. Joe likes to stay active and depending on the season, loves to ' +
    'lace up the skates, cleats, and boxing gloves.';

const DANIEL_KEENE_BIO = 'Dan worked as a financial analyst for two years after graduating with an economics degree in 2018. Back in college, he was part ' +
    'of the founding team for a startup company that helped small businesses with disposing of their technology responsibly. During that time, he developed ' +
    'a passion for helping people which has only increased with his experience. Dan is a big believer in serving his community with a relentless positive ' +
    'attitude that improves the quality of life for everyone around him. He played rugby for 8years through schooling and is currently retired, but has plans ' +
    'to put the boots back on again at some point. But for now, he is pursuing his PHD in roofing and increasing the value of your home.';


const JAMES_MCCARTY_BIO = 'James was born and raised in Northern Virginia and went to West Virginia University for college where he earned his B.S. in Sport & Exercise Psychology. James has had past ' +
    'jobs in sheet metal fabrication and healthcare, as he was once considering med school. He opted for a life of diagnosing and treating roofs as opposed to people as they require less anesthesia. He ' +
    'enjoys cooking for his friends and family in his free time and has an unhealthy obsession with watching random youtube videos at 3 am.';
const HUNTER_ARNOLD_BIO = 'Hunter is 25 and grew up in Gambrills Maryland, and has lived there his whole life. He spent the last couple years in the restaurant industry selling fajitas, ' +
    'but has now decided to pursue a career as a Roof Doc. He is a huge hockey and gym guy and that’s usually how he spends his free time when he isn\'t inspecting roofs.';
const MIKE_MAC_BIO = 'Mike grew up in the Annapolis area and attended West Virginia University where he played club hockey. He graduated in 2020 with a degree in energy management and a minor ' +
    'is business administration. In his free time he enjoys playing sports, hunting/fishing, hanging with friends and being outdoors. He is excited to be part of the roof docs and can’t ' +
    'wait to see what the future holds!';

// const DAVE_LAGARDE_BIO = 'Born and raised in Northern Virginia, David’s background in construction and home improvement runs the gamut - From civil engineering and landscape design, to exterior ' +
//     'remodeling.  He approaches every project with a comprehensive vision and creative bent of mind.  The guy even has a BA in Film and Television Production from George Mason University!  He simply ' +
//     'loves building things.  Whether it’s his daughter’s appreciation for nature, a healthy physique in the gym, or your home’s aesthetic, he can promise that he gives every endeavor 110%';
const JIMMY_SNYDER_BIO = 'Jimmy was born and raised in the DMV area. He is half Lebanese and half American. He grew up in Clifton, VA and then moved to Fort Washington, MD so to say the least he is ' +
    'no stranger to diversity. Jimmy has a passion for racing and cars, and was a Porsche Technician for 5 years. Jimmy has a strong belief in his faith, family, treating people with respect ' +
    'and being honest and true. His people skills go above and beyond and he is excited to be a Roof Doc!';
const ARAM_GHANBARI_BIO = 'Aram is a native Virginian, who graduated from George Mason University where he achieved a B.S. Finance. He now has his PhD in roofing and is a certified Roof Doc. In his free time he is a ' +
    'regular at CrossFit gym and loves going on outdoor adventures with his gorgeous husky!';
const ETHAN_DSOUZA_BIO = 'Ethan started his career as an engineer and management consultant working for fortune 500 companies. After nearly a decade, he changed focus to pursuing family ' +
    'life and work focused on improving the lives of his neighbors in a more meaningful and tangible way. His career passion is now helping homeowners improve the value and ' +
    'aesthetics of their homes through the Roof-ER 4 step process. He loves working with elderly homeowners and immigrants who struggle to navigate home repairs by themselves.';
const ZACH_MYERS_BIO = 'Zach was introduced into the remodeling industry in North Carolina and always felt passionate about helping people. He was in the fitness industry ' +
    'for quite some time as a Vice President but wanted to pursue his passion in the roofing industry. While not on a roof you can find him in the gym or at the beach!';
const MIKE_RAFTER_BIO = 'While growing up in Massachusetts, Mike developed a passion for real estate construction and development. From a young age, Mike’s dad would bring him to his ' +
    'residential development projects to explore the new houses while under construction. After graduating from The University of Tampa, Mike spent eight years in commercial real estate ' +
    'brokerage, much of his time focused on selling residential development sites in NYC. Now living in the DMV area, Mike is excited to guide homeowners though major projects they undertake ' +
    'while committing to delivering exceptional quality. When not on the job site, Mike enjoys staying active and traveling whenever he gets the chance.';
const BRYAM_GODOY_BIO = 'Bryam has been in the construction industry for 8 years and has always had a knack for fixing things. Whether it\'s his own projects around the house or doing repair work for a customer, ' +
    'he enjoys taking the time and attention to get the job done right. He enjoys soccer, basketball, and spending time with his young son';
const KYLE_GOTT_BIO = 'Kyle is Maryland born but primarily “Wild and Wonderful West Virginia” raised thus sparking the interest and appreciation for more hands-on work at an ' +
    'early age. Even though he holds his B.S. in Computer & Information Tech from Marshall University, Kyle soon realized post graduation a job behind the desk ' +
    'wasn’t for him. Since graduating he spent many years helping others in the fitness industry but was later introduced to the roofing world where he can continue ' +
    'to help others in addition to rekindling those WV roots.'
const JESSE_BROUHARD_BIO = 'Originally from New Jersey, moved to VA in 2016 for his automotive career. Spent the past 5 years as a Porsche technician and later became the shop ' +
    'foreman at Porsche of Tysons Corner. With the birth of his first child looking for a change of work pace and lifestyle he joined the Roof-ER team to balance his time with ' +
    'his growing family.'
const CARLOS_DAVILA_BIO = 'Carlos is a Northern VA native. Played soccer at North Carolina Wesleyan College. Loves all sports but especially Soccer and Mixed Martial ' +
    'Arts which makes Carlos A KO when it comes to sales'
const PATRICK_OHARA_BIO = 'Patirck went to West Virginia University.  He has always been interested in restoration contract work. When he is not on a roof, he enjoys fishing and hanging out with friends and family'
const ZAC_NEWDICK_BIO = 'Zac is from Rotorua, New Zealand. He recently graduated from Missouri State University in the fall of 21’ with an International Business Degree ' +
    'while playing Division one soccer. He is excited to be out of the Midwest and into a more pleasant area of the country. Now, Zac prides himself on giving the best customer ' +
    'service he can to homeowners and going above and beyond their expectations. On most weekends you’ll see Zac on the golf course or tuning into a bit of Soccer or Rugby. '
const DALE_ARBAUGH_BIO = 'Dale grew up in Southern Maryland where he was first introduced to the home remodeling business while working for his family\'s construction company. ' +
    'After attending the College of Southern Maryland he found his way back to the remodeling business as a proud member of The Roof Docs. In his spare time, Dale enjoys ' +
    'playing golf, fishing, and training. He looks forward to meeting and working with you'

// const MATT_BIO = 'Matt has over 5 years of experience in the Roofing/Home Remodeling industry.  Before that he attended West Virginia University in which he achieved majors in ' +
//     'Communication Studies, History, and Criminology.  Matt’s previous jobs were in logistics with the Department of Defense, freight brokering, and medical device sales. He enjoys spending ' +
//     'time near the water, his Goldendoodle Willis, and friends and family.  Matt finds his  gratitude through helping homeowners and improving the look of our neighborhoods. He is convinced ' +
//     'he holds the title of Mr. Basketball in the office.';
// const RYAN_FERGUSON_BIO = 'Ryan was born and raised in Northern Virginia where he studied Business Administration ' +
//     'and Communications. Prior to becoming a Roof Doctor, he worked as a marketing manager in the home ' +
//     'improvement industry where he developed a passion for helping customers love their homes. Ryan enjoys ' +
//     'spending time with friends, family, and his Border Collie Yawkey. He has requested to hire ' +
//     'Yawkey to help him with roof inspections, but it\'s against company policy to allow dogs on roofs.';
// const TIM_BLACKWELL_BIO = 'Tim is a born and raised native of the southern Maryland region. He grew up playing a variety of sports like baseball, soccer, ' +
//     'and football, and also spent his youth sharpening his performance in academics—especially math, science, and spanish. After High school, Tim attended college while ' +
//     'exploring different jobs, hobbies, skills, and volunteer work. After working in customer service, He noticed the success he had relative to his coworkers in terms of ' +
//     'keeping customer satisfaction at a maximum. Tim now uses what he has learned over the years to carry on at University as an Information Science undergraduate while ' +
//     'providing value with RoofER and their clients. In his down time, Tim is either trying new things, relaxing, exercising, or spending time with friends and family.';
// const CHASE_STRAIT_BIO = 'Chase was born in Alabama, and spent half of his life up through high school bouncing back in between Virginia and Alabama. While he has ' +
//     'the same stature as a ladder (tall and lanky), this is his first gig climbing up onto people’s roofs. Prior to becoming a roof doctor, he was an Event Manager at ' +
//     'Topgolf, where he focused on directing and overseeing events ranging from birthday parties, to the Redskins and Capitals, to the President of the World National ' +
//     'Bank. Chase went to Northern Virginia Community College, where he got his Associate’s in Business Administration, then continued to Longwood where he played ' +
//     'soccer and continued his Business Administration degree with a concentration in Cyber Security.  Chase is a passionate sports player, dog lover (has 2 ' +
//     'beautiful Huskies who he swears have worse ADHD than himself), and human garbage disposal when it comes to food.';
// const CORMAC_FORAN_BIO = 'Cormac grew up in the Annapolis area and attended Clemson university, graduating in 2018 with a bachelors of science in economics and a minor in management. ' +
//     'In his free time, he enjoys going to the beach and playing a variety of sports/pretty much anything that keeps him active. He also enjoys traveling and loves being outdoors (especially on roofs). ' +
//     'He is excited to be a part of the team!';

const DOCS = [
    { name: 'Michael Harvey', position: 'President / Job Lead', photo: Mike, bio: MIKE_BIO },
    { name: 'Oliver Brown', position: 'Manager', photo: Oliver, bio: OLIVER_BIO },
    { name: 'Chris Davis', position: 'Manager', photo: ChrisDavis, bio: CHRIS_BIO },

    { name: 'Mary Lombardi', position: 'Executive Assistant', photo: MaryLombardi, bio: MARY_BIO },
    { name: 'Rees Nalley', position: 'President / Job Lead', photo: Rees, bio: REES_BIO },
    { name: 'Greg Campbell', position: 'Team Leader', photo: GregCampbell, bio: GREG_BIO },
    { name: 'Austin Stephens', position: 'Team Leader', photo: AustinStephens, bio: AUSTIN_BIO },
    { name: 'Matt Ray', position: 'Team Leader', photo: MattRay, bio: MATT_RAY_BIO },
    { name: 'Ford Barsi', position: 'Team Leader', photo: FordBarsi, bio: FORD_BIO },
    { name: 'Patrick Robertson', position: 'Team Leader', photo: PatrickRobertson, bio: PATRICK_BIO },
    { name: 'Luis Esteves', position: 'Team Leader', photo: LuisEsteves, bio: LUIS_ESTEVES_BIO },
    { name: 'Ismael Coreas', position: 'Team Leader', photo: IsmaelCoreas, bio: ISMAEL_COREAS_BIO },
    // { name: 'Dave LaGarde', position: 'Team Leader', photo: DaveLaGarde, bio: DAVE_LAGARDE_BIO },
    { name: 'Joe McElhenny (Mac)', position: 'Team Leader', photo: JoeMac, bio: JOE_MAC_BIO },
    { name: 'Daniel Keene', position: 'Team Leader', photo: DanielKeene, bio: DANIEL_KEENE_BIO },
    { name: 'James McCarty', position: 'Team Leader', photo: JamesMcCarty, bio: JAMES_MCCARTY_BIO },
    { name: 'Mike Mac', position: 'Team Leader', photo: MikeMac, bio: MIKE_MAC_BIO },
    { name: 'Hunter Arnold', position: 'Team Leader', photo: HunterArnold, bio: HUNTER_ARNOLD_BIO },
    { name: 'Jimmy Snyder', position: 'Team Leader', photo: JimmySnyder, bio: JIMMY_SNYDER_BIO },
    { name: 'Aram Ghanbari', position: 'Team Leader', photo: AramGhanbari, bio: ARAM_GHANBARI_BIO },
    { name: 'Ethan D\'Souza', position: 'Team Leader', photo: EthanDsouza, bio: ETHAN_DSOUZA_BIO },
    { name: 'Zach Myers', position: 'Team Leader', photo: ZachMyers, bio: ZACH_MYERS_BIO },
    { name: 'Mike Rafter', position: 'Team Leader', photo: MikeRafter, bio: MIKE_RAFTER_BIO },
    { name: 'Bryam Godoy', position: 'Team Leader', photo: BryamGodoy, bio: BRYAM_GODOY_BIO },
    { name: 'Kyle Gott', position: 'Team Leader', photo: KyleGott, bio: KYLE_GOTT_BIO },
    { name: 'Jesse Brouhard', position: 'Team Leader', photo: JesseBrouhard, bio: JESSE_BROUHARD_BIO },
    { name: 'Carlos Davila', position: 'Team Leader', photo: CarlosDavila, bio: CARLOS_DAVILA_BIO },
    { name: 'Patrick O\'Hara', position: 'Team Leader', photo: PatrickOHara, bio: PATRICK_OHARA_BIO },
    { name: 'Zac Newdick', position: 'Team Leader', photo: ZacNewdick, bio: ZAC_NEWDICK_BIO },
    { name: 'Dale Arbaugh', position: 'Team Leader', photo: DaleArbaugh, bio: DALE_ARBAUGH_BIO }


];

class MeetTheDocs extends Component {

    render() {
        return (
            <div className="meet-the-docs-container">

                <div className="docs-banner">
                    <div className="docs-banner-text-container">
                        <p className="banner-text-small"> INTEGRITY </p>
                        <p className="banner-text-large"> Honesty First. And Second. And Third... </p>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon" />
                </div>

                <p className="docs-oath"> Our Hippocratic Oath: to hold a fiduciary responsibility to each and every one of our customers through a commitment to our core values of integrity, quality, and simplicity </p>

                <div className="docs-list-container">
                    { DOCS.map( (doc,index) => (
                        <DocDisplay key={doc.name} parent={this} doc={doc} index={index} />
                    ))}
                </div>

                <div className="gray-banner" />
                <FreeQuote />

                <ViewDocModal ref="viewDoc"/>
            </div>
        );
    }

    showDoc(index) {

        // TODO: Set Doc
        this.refs.viewDoc.setState({ doc: DOCS[index]});
        this.refs.viewDoc.show();
    }
}

class DocDisplay extends Component {

    showDocBio = e => {

        e.preventDefault();
        this.props.parent.showDoc( this.props.index);
    };

    render()  {

        return (
            <div className="doc-container">
                <div className="doc-image-container">
                    <img src={this.props.doc.photo} alt={this.props.doc.name}/>
                </div>
                <div className="doc-icon-container">
                    <img className="doc-expand-icon" onClick={this.showDocBio} src={ EXPAND_ICON } alt="Doc Bio"/>
                </div>
                {/*<p className="doc-position"> {this.props.doc.position }</p>*/}
                <p className="doc-name"> {this.props.doc.name }</p>
            </div>
        )
    }
}


export default MeetTheDocs;

class ViewDocModal extends Component {

    constructor(props){
        super(props);

        this.state = { hidden: true, doc: null };

    }

    show() {
        this.setState({ hidden: false });
    }


    closeModal() {

        this.setState({hidden: true});
    }


    render() {
        if(this.state.doc) {
            return (
                <div className= {"modalContainer" + (this.state.hidden ? ' hiddenModal' : '')} onClick={this.closeModal.bind(this)}>
                  <Helmet>
                       <title>Our Team | Tysons Corner Roofing Company | Roof-ER</title>
                       <meta name="description" content="Meet our experienced team of local VA roofers. Our technicians make us the top roof repair company in Tysons! Call us to schedule your free roof inspection!" />
                   </Helmet>
                    <div className="view-doc-modal-content">

                        <div className="doc-container-modal">
                            <div className="doc-image-container-modal">
                                <img src={this.state.doc.photo} alt={this.state.doc.name}/>
                            </div>
                            <div className="doc-icon-container-modal">
                                <img className="doc-expand-icon" onClick={this.closeBio} src={ COLLAPSE_ICON } alt="Collapse Bio"/>
                            </div>
                            {/*<p className="doc-position"> {this.state.doc.position }</p>*/}
                            <p className="doc-name"> {this.state.doc.name }</p>
                            <div className="doc-bio-modal-content">
                                <p> { this.state.doc.bio }</p>
                            </div>
                        </div>

                    </div>
                </div>
            );
        } else {
            return ( <div /> )
        }

    }

    closeBio = e => {
        e.preventDefault();
        this.setState ({ doc: null, hidden: true });
    }
}
