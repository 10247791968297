import React, { Component } from 'react';

// STYLING
import '../../style/components/custom/imageInput.scss';

// IMAGES
import Address from '../../resources/images/inputIcons/icon_sm_address_gry.png';
import Name from '../../resources/images/inputIcons/icon_sm_name_gry.png';
import Email from '../../resources/images/inputIcons/icon_sm_email_gry.png';
import Phone from '../../resources/images/inputIcons/icon_sm_phone_gry.png';
import Code from '../../resources/images/inputIcons/code.png';


const Placeholders = {
    streetAddress: 'Enter your address',
    city: 'Enter your city',
    state: 'Enter your state',
    zipCode: 'Enter your zip code',
    fullName: 'Enter your name',
    firstName: 'Enter your first name',
    lastName: 'Enter your last name',
    email: 'Enter your email',
    phone: 'Enter your phone number',
    code: 'Enter your promotion code',
    roofAge: 'Enter the age of your roof'

};

const normalizePhoneInput = (value, previousValue) => {
    if (!value) return value; // return nothing if no value

    const onlyNums = value.replace(/[^\d]/g, ''); // only allows 0-9 inputs

    if (!previousValue || value.length > previousValue.length) {
        // returns: "(xxx)"
        if (onlyNums.length === 3) return `(${onlyNums})`;

        // returns: "(xxx) xxx-"
        if (onlyNums.length === 6) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}-`

        // returns: "x", "xx", "xxx"
        if (onlyNums.length <= 3) return onlyNums;

        // returns: "(xxx) x", "(xxx) xx",
        if (onlyNums.length <= 6) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;

        // returns: "(xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx",

        return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    }
};


class ImageInput extends Component {


    constructor(props) {
        super(props);

        this.state = {value: ''}
    }


    render() {

        let src = null;
        switch (this.props.type) {
            case 'address':
                src = Address;
                break;
            case 'city':
                src = Address;
                break;
            case 'state':
                src = Address;
                break;
            case 'zipCode':
                src = Address;
                break;
            case 'fullName':
                src = Name;
                break;
            case 'firstName':
                src = Name;
                break;
            case 'lastName':
                src = Name;
                break;
            case 'email':
                src = Email;
                break;
            case 'phone':
                src = Phone;
                break;
            case 'code':
                src = Code;
                break;
            default:
                break;
        }

        return (
            <div className="image-input-container">
                <img style={this.props.type === 'roofAge' ? {display: 'none'} : {}} className={this.props.type}
                     src={src} alt="Input Icon"/>
                <input onChange={this.valueChanged} value={this.state.value}
                       placeholder={Placeholders[this.props.type]}/>
            </div>

        );
    }

    valueChanged = e => {

        if(this.props.type === 'phone'){

            let phone = this.state.value
            const normalized = normalizePhoneInput(e.target.value, phone);

            this.setState({ value: normalized });


        } else {

            this.setState({value: e.target.value});

        }

        // if (this.props.type === 'address') {
        //     this.googlePlacesSearch( e.target.value );
        // }
    };

    // googlePlacesSearch( searchText ) {
    //
    //     const self = this;
    //     const API_KEY = 'AIzaSyAE-QBsMAoN0ivgeIm_dU-mInEr7n_wJlA';
    //     const URL = `https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${API_KEY}&types=address&input=${searchText}`
    //     axios.get(URL, {
    //         headers: {
    //             'Access-Control-Allow-Origin': '*'
    //         }
    //     }).then(function (response) {
    //         console.log(response);
    //     }).catch(function (error) {
    //         const message = error.message ? error.message : 'Error getting available appointments, please try again';
    //         console.log(message);
    //     });
    // }

}

export default ImageInput;