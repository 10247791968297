import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import FreeQuoteBanner from "../custom/freeQuoteBannerRed";

// Styling
import '../../style/components/mission.scss';
import '../../style/components/services/template.scss';

import BANNER_ICON_UP from "../../resources/images/misc/plus_icon_up.png";

class SidingReplacement extends Component {

    render() {
        return (
            <div className="services-container">
                {/* Banner */}
                <Helmet>
                    <title>Siding Replacement Company in Fairfax County | Roof-ER</title>
                    <meta name="description" content="Roof-ER offers the top siding replacement service in Tysons Corner. Learn more about our replacement capabilities. Schedule your free inspection today!"/>
                </Helmet>
                <div className="services-banner">
                    <div className="services-banner-text-container">
                        <p className="banner-text-small"> Siding Replacement Services </p>
                        <p className="banner-text-large"> Roof-ER is your siding replacement expert</p>

                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <FreeQuoteBanner />
                <div className="services-template-container">
                    <div className="services-template-content">
                        <h1>Siding Replacement Services</h1>
                        <p className="main-text">
                          Whether due to storm activity or an accident, your home's siding may
                          need to be replaced from time to time. Damaged siding needs to be
                          addressed by a siding professional as soon as you notice it, as
                          untreated damage to this part of your home's exterior can turn into a
                          serious — and costly — problem down the road.
                        </p>
                        <p className="main-text">
                            If you need siding replacement services in Tysons, Virginia or the surrounding areas, the siding replacement professionals
                            at,&nbsp;<a title="" href="/docs" target="The Roof Docs">The Roof Docs</a>&nbsp;can help.
                            We'll work with your insurance to provide streamlined repair and replacement solutions that save you time and money while helping you maintain a beautiful home.
                        </p>

                        <h2>Why Do I Need to Replace My&nbsp;Aluminum&nbsp;Siding?</h2>
                        <p>
                          Your siding protects you from wind, rain, snowfall and other weather
                          elements that can be hazardous to your home. When your siding endures
                          stormy weather, it can crack, blow off, or become dented, which
                          compromises its ability to function properly or severely affects its
                          aesthetics.
                        </p>
                        <p>
                          Damaged&nbsp;aluminum&nbsp;siding often requires replacement. With The
                          Roof Docs, you have access to a certified team of professional siding
                          installers who can perform siding replacements and a wide range of
                          other necessary services. If your siding is damaged alongside your
                          roof due to a windstorm or hailstorm, we're your one-stop-shop for
                          repairs.
                        </p>
                        <h2>Common&nbsp;Aluminum&nbsp;Siding Problems</h2>
                        <p>
                          Aluminum&nbsp;siding may need replacement for a variety of reasons.
                          Some of the most common problems we address with our siding
                          replacement services in Fairfax County include the following:
                        </p>
                        <h3>Dents From Hail Damage&nbsp;</h3>
                        <p>
                          Aluminum siding is more susceptible to hail damage. Large hail can
                          make permanent punctures and dents in aluminum siding because of the
                          material's composition, and strong storms are known to leave oxidation
                          marks on aluminum siding. When this happens, it's a smart idea to have
                          your aluminum siding replaced.
                        </p>
                        <h3>Water Damage</h3>
                        <p>
                          Especially in rainy climates,&nbsp;aluminum&nbsp;siding is prone to
                          water damage.&nbsp;Water leaks can damage walls and cause the aluminum
                          siding to rust.&nbsp;
                        </p>
                        <h3>Interior Wall Damage</h3>
                        <p>
                          If your interior walls are damaged, it's a sure sign that water is
                          leaking in from the outside — most likely due to siding
                          problems.&nbsp;Aluminum siding has often been used to cover up
                          structural damage in older homes.&nbsp;
                        </p>
                        <h3>Increasing Energy Bills</h3>
                        <p>
                          There are many reasons for increased energy bills. However, if your
                          energy consumption spikes for no discernible reason, the culprit may
                          be damaged aluminum siding disrupting your home's temperature control.
                        </p>
                        <h2>Benefits of Vinyl Siding</h2>
                        <p>
                            <a title="" href="/blog/right-siding" target="Vinyl siding">
                                Vinyl siding</a>&nbsp;has several advantages over aluminum. Vinyl siding possesses the following benefits:
                        </p>
                        <ul>
                          <li>
                            <p>
                              <strong>Durability - </strong> Vinyl siding is designed to withstand
                              the stress of the elements. Aluminum siding is much more
                              susceptible to damage from wind and hail.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Style -&nbsp;</strong>Attractive vinyl siding can improve
                              the resale value of your home. You can select the color and
                              texture that best suits your home's style.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Low Maintenance -&nbsp;</strong>Vinyl siding is incredibly
                              easy to maintain. Dirt and grime can be easily removed with a hose
                              or power washer.
                            </p>
                          </li>
                        </ul>
                        <h2>
                          Siding Replacement Services and Free Siding Inspection in Fairfax
                          County
                        </h2>
                        <p>
                            When you need siding replacement in Vienna or the surrounding communities, turn to The Roof Docs at
                            Roof-ER.  <a title="" href="/inspection" target="Schedule your freesiding inspection">
                                Schedule your free siding inspection</a>&nbsp;in Fairfax County by calling 703-239-3738 today.
                        </p>
                    </div>
                </div>

            </div>
        )
    }
}

export default SidingReplacement;