import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";
import TitleImage from "../../../resources/images/blogs/soffit-vents/what-are-soffit-vents.png";
import Inspection from "../../../resources/images/blogs/soffit-vents/02-explore-soffit-vents-with-roofer.png";

class SoffitVents extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';

        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                        <Helmet>
                            <title>What Are Soffit Vents? | Roof-ER</title>
                            <meta name="description" content="Soffit vents can drastically improve your roof's life span by increasing air circulation in your attic. Contact Roof-ER today for a free roof inspection!"/>
                        </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content" style={{ width: '100%'}}>
                        <div className="blog-item-container">
                            <div className="blog-item-info-container-full">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                            </div>
                            <div className="blog-item-info-container-full">
                                <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                            </div>
                            <img className="long-form-image" src={TitleImage} alt="Title" />
                            <div className="blog-item-info-container-full">

                                <h1>What Are Soffit Vents?</h1>
                                <p><a href="/blog/roof-ventilation" target="_blank" rel="noopener noreferrer">Roof ventilation</a>&nbsp;is an important part of properly maintaining any home. Soffit vents provide quality air circulation to ventilate your attic, which can help extend the life of your roof. These are vents placed on the underside of your home's eaves, where they work to bring cooler air into your attic. Most homes are fitted with some type of vent to serve this purpose, but the addition of soffit vents can greatly increase their efficiency.&nbsp;</p>
                                <p>When they work together, the cool air enters through the soffit vent at the base of your roof, and the warmer air escapes through the other vents located toward the peak. This creates a beneficial flow of air that keeps your attic in good condition throughout the year.&nbsp;</p>
                                <h2>What Are the Benefits of Soffit Vents?</h2>
                                <p>During the summer months, the added airflow provided by the soffit vents moves the warm air out of the attic and replaces it with fresh air. This process helps your house stay cool, removes moisture and cuts down on air conditioning costs. A similar benefit can be seen during the winter, as adequate ventilation can remove the condensation created by cooking, showering and heating your home. It can also help prevent ice dams, which form when you have nonuniform surface temperatures on your roof.&nbsp;</p>
                                <p>These soffit vents can also extend the life span of your roof, as they help protect your shingles, lower cooling costs and help reduce moisture that can lead to mold, mildew and wood rot.&nbsp;</p>
                                <h2>What Types of Soffit Vents Are There?</h2>
                                <p>There are two main types of soffit vents that you can choose to install in your home &mdash; continuous and individual. While they do the same job, they differ in size, shape and effectiveness.&nbsp;</p>
                                <ul>
                                    <li><strong>Continuous soffit vent:</strong>&nbsp;The continuous soffit vents tend to be longer and wrap around the eaves of your entire home. This type of soffit vent provides more surface area, ultimately increasing air circulation. They are typically made of vinyl with drilled intake holes.&nbsp;</li>
                                    <li><strong>Individual soffit vent:</strong>&nbsp;These vents are smaller, rectangular or circular vents spread out about 5 to 6 feet apart from each other. This kind of vent produces less surface area for air intake.&nbsp;</li>
                                </ul>
                                <p>Newer homes tend to have these vents already installed, but many older houses are missing them. You can take a quick walk around your home and check under the eaves for soffit vents to determine if you have this ventilation system.</p>
                            </div>

                            <a href="/inspection"><img className="long-form-image-clickable" src={Inspection} alt="Inspection" /></a>
                            <div className="blog-item-info-container-full">
                                <h2>Explore Soffit Vents With Roof-ER</h2>
                                <p>Are you looking to improve the ventilation of your roof? Contact Roof-ER for a no-cost roof inspection in Northern Virginia or Maryland! Our team can help you find which soffit vent will be best suited for your roof's needs.&nbsp;</p>
                                <p>Call us today at (703) 239-3738 or&nbsp;<a href="/inspection" target="_blank" rel="noopener noreferrer">request a free inspection online</a>!</p>
{/*                                <script type="application/ld+json">*/}
{/*{*/}
{/*  "@context": "https://schema.org",*/}
{/*  "@type": "FAQPage",*/}
{/*  "mainEntity": [{*/}
{/*    "@type": "Question",*/}
{/*    "name": "What Are Soffit Vents?",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "Soffit vents provide quality air circulation to ventilate your attic, which can help extend the life of your roof. These are vents placed on the underside of your home's eaves, where they work to bring cooler air into your attic. Most homes are fitted with some type of vent to serve this purpose, but the addition of soffit vents can greatly increase their efficiency."*/}
{/*    }*/}
{/*  },{*/}
{/*    "@type": "Question",*/}
{/*    "name": "What Are the Benefits of Soffit Vents?",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "During the summer months, the added airflow provided by the soffit vents moves the warm air out of the attic and replaces it with fresh air. This process helps your house stay cool, removes moisture and cuts down on air conditioning costs. A similar benefit can be seen during the winter, as adequate ventilation can remove the condensation created by cooking, showering and heating your home. It can also help prevent ice dams, which form when you have nonuniform surface temperatures on your roof. */}

{/*These soffit vents can also extend the life span of your roof, as they help protect your shingles, lower cooling costs and help reduce moisture that can lead to mold, mildew and wood rot."*/}
{/*    }*/}
{/*  },{*/}
{/*    "@type": "Question",*/}
{/*    "name": "What Types of Soffit Vents Are There?",*/}
{/*    "acceptedAnswer": {*/}
{/*      "@type": "Answer",*/}
{/*      "text": "There are two main types of soffit vents that you can choose to install in your home — continuous and individual. While they do the same job, they differ in size, shape and effectiveness. */}

{/*Continuous soffit vent: The continuous soffit vents tend to be longer and wrap around the eaves of your entire home. This type of soffit vent provides more surface area, ultimately increasing air circulation. They are typically made of vinyl with drilled intake holes. */}

{/*Individual soffit vent: These vents are smaller, rectangular or circular vents spread out about 5 to 6 feet apart from each other. This kind of vent produces less surface area for air intake."*/}
{/*    }*/}
{/*  }]*/}
{/*}*/}
{/*</script>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SoffitVents;