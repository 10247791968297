import React, { Component } from 'react';
// import { useS } from 'react-router-dom';
// import { useSearchParams } from 'react-router-dom'

import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";
import TitleImage from "../../../resources/images/blogs/architectural-shingles/01-What-Are-Architectural-Shingles_.jpg";
import Inspection from "../../../resources/images/blogs/architectural-shingles/02-Get-a-Free-Inspection-With-Roof-ER.jpg";

class ArchitecturalShingles extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const ref = params.get('ref') ? params.get('ref') : '0';


        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                        <Helmet>
                            <title>What Are Architectural Shingles? | Roof-ER</title>
                            <meta name="description" content="Are you looking for a new roof? Architectural roofing shingles are high-quality roofing materials that you'll love. Contact Roof-ER for a free inspection today!"/>
                        </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content" style={{ width: '100%'}}>
                        <div className="blog-item-container">
                            <div className="blog-item-info-container-full">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                            </div>
                            <div className="blog-item-info-container-full">
                                <a href={`/blog?ref=${ref}&dir=prev`}><button  className="blog-previous-button"><span className="arrow-left"> Previous Article </span> </button></a>
                                <a href={`/blog?ref=${ref}&dir=next`}><button className="blog-next-button"><span className="arrow-right"> Next Article </span> </button></a>
                            </div>
                            <img className="long-form-image" src={TitleImage} alt="Title" />
                            <div className="blog-item-info-container-full">
                                <h1>What Are Architectural Shingles?</h1>
                                <p>If it's time to upgrade your roof, the material you choose for the shingles can make all the difference. Architectural shingles, which are also referred to as laminated or dimensional shingles, are a popular choice that can add a lot of value to your home.</p>
                                <p>If your house was built before the 1980s and you haven't installed a new roof, your current roof is probably made of the standard three-tab asphalt roofing shingles. Architectural roofing shingles are a more recent development. While many people choose these shingles for their appearance, they also offer improvements to durability and life span.</p>
                                <h2>What Are Architectural Shingles Made Of?</h2>
                                <p>These enhanced shingles are made from a fiberglass mat base with ceramic coated minerals and embedded with water-resistant asphalt. Unlike regular asphalt shingles, architectural shingles are laminated, meaning that two or more shingles are bonded together. This construction creates a thicker, contoured look, giving the roof a more multidimensional feel and extra protection.&nbsp;</p>
                                <h2>The Benefits of Architectural Shingles</h2>
                                <p>You have a few options when it comes to roofing materials. Here are some factors that set architectural design shingles apart.</p>
                                <h3>Durability</h3>
                                <p>Architectural-grade shingles are an excellent option in terms of durability. While asphalt shingles can handle up to 60 mph winds, architectural shingles are made to withstand up to 120 mph winds. They also stand strong against elements like rain, snow, hail and heat. This durability translates to a longer life span.</p>
                                <h3>Aesthetics</h3>
                                <p>Many homeowners are drawn to architectural design shingles based on looks alone. These shingles will add a three-dimensional element to your roof, which can increase your home's curb appeal and help you get a better price if you decide to sell down the road. They come in various styles and can be made to resemble the look of cedar shakes and slate without the worries of flammability and extra maintenance.&nbsp;</p>
                                <p>If you value the look of your home, architectural shingles are a great way to add a sense of prestige and class to your property.&nbsp;</p>
                                <h3>Cost-Effectiveness</h3>
                                <p>The cost of materials is a big part of any decision when you're looking to update your home. While architectural laminated shingles come at a higher price than some other options, they tend to be more cost-effective in the long run due to their long life span and durability. Typical asphalt shingles wear out quicker, meaning you'll have to replace them sooner.&nbsp;</p>
                                <h2>Get a Free Inspection With Roof-ER</h2>
                                <p>Installing a new roof is a big decision. It's essential to find a team you can trust to get the job done right for an affordable price! Contact Roof-ER for help finding a new roof you'll love. Call us at 703-239-3738 or&nbsp;<a href="/inspection" target="_blank" rel="noopener noreferrer">schedule your free inspection online</a>&nbsp;to get started.&nbsp;</p>
                            </div>
                            <a href="/inspection"><img className="long-form-image-clickable" src={Inspection} alt="Inspection" /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default ArchitecturalShingles;