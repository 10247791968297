import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import GoogleReviews from "./custom/googleReviews";


// Styling
import '../style/components/mission.scss';
import '../style/components/work.scss';

import BANNER_ICON_UP from "../resources/images/misc/plus_icon_up.png";
import INTEGRITY from "../resources/images/integrity.png";
import QUALITY from "../resources/images/quality.png";
import SIMPLICITY from "../resources/images/simplicity.png";

class OurMission extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {
        return (
            <div className="mission-container">
                <div className="mission-banner">
                    <div className="mission-banner-text-container">
                    <Helmet>
                        <title>Our Mission Statement | Tysons Corner Roofer | Roof-ER</title>
                        <meta name="description" content="The Roof Docs are a Fairfax County roof contractor committed to quality craftsmanship. Learn about our mission statement. Sign up for a free roof inspection! " />
                    </Helmet>
                        <p className="banner-text-small"> Our Mission? </p>
                        <p className="banner-text-large"> To Raise the Standard for Roofing Contractors  </p>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="work-process-container">
                    <div className="work-process-content">

                        {/* OUR MISSION */}
                        <div className="work-item-container">
                            {/*<div className="work-item-icon-container">*/}
                            {/*    <img src={STEPS[0].icon} alt="Step" />*/}
                            {/*</div>*/}
                            <div className="work-item-info-container">
                                <p className="step-header"> The ROOF<span style={{color: 'black'}}>ER</span> Mission </p>
                                <p className="step-info"> At ROOF<span className="roofDocsRed">ER</span>, our mission is to hold a fiduciary responsibility to our customers, plain and simple. In an
                                    industry known for poor workmanship, lack of communication, and at times, outright deceit, we seek to restore the name of
                                    exterior remodeling contractors nationwide. By committing to our core values of integrity, quality, and simplicity, we promise
                                    to deliver an experience every homeowner wants when remodeling their home: <span className="step-info-bold">a simple and straightforward quality installation
                                        for a fair and honest price.</span>  </p>

                            </div>
                        </div>

                    </div>

                    <div className="work-process-content">

                        {/* OUR MISSION */}
                        <div className="work-item-container">
                            {/*<div className="work-item-icon-container">*/}
                            {/*    <img src={STEPS[0].icon} alt="Step" />*/}
                            {/*</div>*/}
                            <div className="work-item-info-container">
                                <p className="step-header"> Our Values </p>
                            </div>
                        </div>


                        {/* INTEGRITY */}
                        <div className="work-item-container">
                            <div className="work-item-icon-container">
                                <img src={ INTEGRITY } alt="Integrity" />
                            </div>
                            <div className="work-item-info-container">
                                <p className="step-header"> 1. INTEGRITY  </p>
                                <p className="step-info"> Great businesses are built on trust: trust in the people, the process, and the product.
                                    ROOF<span className="roofDocsRed">ER</span>'s commitment to integrity starts with our training methods and continues through
                                    our final quality inspection.  Customers know what to expect from us and as a result, are confident in referring us to their friends
                                    and family.  ROOF<span className="roofDocsRed">ER</span> is proud to say 90% of our new customers are referrals.
                                </p>
                            </div>
                        </div>

                        {/* QUALITY */}
                        <div className="work-item-container">
                            <div className="work-item-icon-container">
                                <img src={ QUALITY } alt="Integrity" />
                            </div>
                            <div className="work-item-info-container">
                                <p className="step-header"> 2. QUALITY  </p>
                                <p className="step-info"> Sustainability doesn't come through shortcuts.  By focusing on quality from start-to-finish,
                                    ROOF<span className="roofDocsRed">ER</span> is quickly becoming the premier roofing contractor in Northern Virginia and Maryland for those customers
                                    looking for a restoration project done right.  Whether it is our detailed inspections or our quality assurance protocol, we focus on
                                    every last detail to ensure our customers know they've received the best service available.
                                </p>
                            </div>
                        </div>

                        {/* SIMPLICITY */}
                        <div className="work-item-container">
                            <div className="work-item-icon-container">
                                <img src={ SIMPLICITY } alt="Integrity" />
                            </div>
                            <div className="work-item-info-container">
                                <p className="step-header"> 3. SIMPLICITY  </p>
                                <p className="step-info"> Complexity = chaos.  After a storm, this equation has the potential to become even worse.
                                    ROOF<span className="roofDocsRed">ER</span> understands what homeowners want most after noticing an issue with their home’s exterior: peace of mind.
                                    Leveraging technology and emphasizing simplicity allows ROOF<span className="roofDocsRed">ER</span> to offer an unrivaled value
                                    proposition: an expert restoration project with minimal headaches.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <GoogleReviews />
            </div>
        )
    }
}

export default OurMission;