import React, { Component } from 'react';
import axios from 'axios';
import ImageInput from '../components/custom/imageInput';
import { Helmet } from 'react-helmet';

// Styling
import '../style/components/inspection.scss';
import '../style/components/freeEstimate.css';
import '../style/components/modal.css'

import SUBMIT_ICON from '../resources/images/misc/btn_icon_arrow_ko.png';
import ImageTextArea from "./custom/imageTextArea";
import ImageMultiSelect from "./custom/imageMultiSelect";
import DateTimeInput from "./custom/dateTimeInput";
import SelectInput from "./custom/selectInput";

import DropDownInput from "./custom/dropdownInput";
import AddressInput from "./custom/addressInput";

class EstimateExternal extends Component {

    constructor(props){
        super(props);

        this.state = { address: '', formSubmitted: false, errors: [], stormReportSubmitted: false, stormReportErrors: [], roofAgeHidden: true, sidingTypeHidden: true}
    }


    render() {

        return (
            <div className="inspection-page-container">
                <Helmet>
                    <title>Free Roof Inspection | Tysons Corner Roofing | Roof-ER</title>
                    <meta name="description" content="Schedule a free roof inspection today with the Roof Docs! We are experts in hail and storm damage roof inspections. Quickly book an appointment!"/>
                </Helmet>
                {/*<div className="inspection-banner">*/}
                {/*    <div className="inspection-banner-text-container">*/}
                {/*        <p className="banner-text-small"> SIMPLICITY </p>*/}
                {/*        <p className="banner-text-large"> Free Inspections for Full Peace of Mind </p>*/}
                {/*    </div>*/}
                {/*    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Up Icon"/>*/}
                {/*</div>*/}

                {/*<p className="virus-disclaimer"> With businesses and citizens forced into quarantine over heightened COVID-19 concerns, ROOF<span className="roofDocsRed">ER</span> has adapted our process to*/}
                {/*    the current environment. We are proud to offer a 100% remote roof replacement experience. Sign up for a free*/}
                {/*    inspection today and read more about our 4-step remote process below</p>*/}

                <div className="inspection-report-container">
                    <div className="inspection-container">
                        {/*<p className="header-text">1. Free Inspection </p>*/}
                        {/*<p className="header-info-text">Fill out the short form below to set up a FREE inspection by one of our Roof Docs today!</p>*/}
                        <ImageInput ref="firstName" type="firstName" />
                        <ImageInput ref="lastName" type="lastName" />
                        <AddressInput parent={this} ref="streetAddress" />
                        <ImageInput  ref="city" type="city" />
                        <ImageInput  ref="state" type="state" />
                        <ImageInput  ref="zipCode" type="zipCode" />
                        <ImageInput ref="inspectionEmail" type="email" />
                        <ImageInput ref="inspectionPhone" type="phone" />
                        <ImageMultiSelect parent={this} ref="inspectionSelect" type="inspection" />
                        <DropDownInput ref="roof" trade="roof" hidden={this.state.roofAgeHidden} />
                        <DropDownInput ref="roofReplacement" trade="roofReplacement" hidden={this.state.roofAgeHidden} />
                        <DropDownInput ref="siding" trade="siding" hidden={this.state.sidingTypeHidden} />
                        <ImageTextArea ref="inspectionDetails" />
                        <DateTimeInput ref="appointmentTime" />
                        <SelectInput ref="referralMethod" custom={true}/>


                        <div className="report-submit-button" onClick={this.submitInspection} >
                            <p className="report-submit-label"> Submit Free Inspection Form </p>
                            <img className="report-submit-img" src={SUBMIT_ICON} alt="Submit" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    addressSelected ( selected ){

        if(selected.city){
            this.refs.city.setState({ value: selected.city })
        }
        if(selected.state){
            this.refs.state.setState({ value: selected.state })
        }
        if(selected.zipCode){
            this.refs.zipCode.setState({ value: selected.zipCode })
        }
    }

    submitInspection = () => {


        const firstName = this.refs.firstName.state.value;
        const lastName = this.refs.lastName.state.value;
        const streetAddress = this.refs.streetAddress.state.address;
        const city = this.refs.city.state.value;
        const state = this.refs.state.state.value;
        const zipCode = this.refs.zipCode.state.value;
        const email = this.refs.inspectionEmail.state.value;
        const phone = this.refs.inspectionPhone.state.value;
        const details = this.refs.inspectionDetails.state.value;
        const roof = this.refs.inspectionSelect.refs.roof.state.checked;
        const siding = this.refs.inspectionSelect.refs.siding.state.checked;
        const gutters = this.refs.inspectionSelect.refs.gutters.state.checked;
        const damage = this.refs.inspectionSelect.refs.damage.state.checked;
        const appointmentTime = this.refs.appointmentTime.state.selectedTime;
        const repEmail = this.refs.appointmentTime.state.repEmail;
        const referralMethod = this.refs.referralMethod.state.value;
        const referralName = this.refs.referralMethod.state.referral;
        const roofAge = this.refs.roof.state.value;
        const roofReplacement = this.refs.roofReplacement.state.value;
        const sidingType = this.refs.siding.state.value;

        // Create Body for API Call
        const inspectionBody = {
            firstName: firstName,
            lastName: lastName,
            email: email.replace(/\s/g, ""),
            phoneNumber: phone,
            addressLine1: streetAddress,
            city: city,
            state: state,
            zipCode: zipCode,
            stormDamage: damage,
            roof: roof,
            roofAge: roofAge,
            roofReplacement: roofReplacement,
            siding: siding,
            sidingType: sidingType,
            gutters: gutters,
            details: details,
            appointmentTime: appointmentTime,
            repEmail: repEmail,
            requestType: 'inspection',
            referralMethod: referralMethod,
            referralName: referralName,
            origination: 'ClickFunnels'
        };

        let errors = [];

        // NAME
        if(inspectionBody.firstName.length === 0) {
            errors.push("First Name");
        }
        if(inspectionBody.lastName.length === 0) {
            errors.push("Last Name");
        }
        if(inspectionBody.city.length === 0) {
            errors.push("City");
        }
        if(inspectionBody.state.length === 0) {
            errors.push("State");
        }
        if(inspectionBody.zipCode.length === 0) {
            errors.push("Zip Code");
        }

        // ADDRESS
        if(inspectionBody.addressLine1.length === 0) {
            errors.push("Address");
        }

        // EMAIL
        const emailRegex = /\S+@\S+\.\S+/;
        if(!emailRegex.test(inspectionBody.email.toLowerCase())){
            errors.push("Invalid Email");
        }

        // PHONE NUMBER
        if(inspectionBody.phoneNumber.length < 10) {
            errors.push("Phone Number")
        }

        if(!appointmentTime) {
            errors.push("Appointment Time")
        }

        if(errors.length === 0) {

            // const self = this;
            const self = this;
            axios.post(`${process.env.REACT_APP_API_URL}inquiry`, inspectionBody)
                .then(function (response) {

                    // Redirect to Thank You Page
                    window.location.href = "/inspection/external";


                    //
                    // // Clear Text Fields
                    // self.clearInspectionFields();

                }).catch(function (error) {
                console.log(error);
                // Show Modal
                self.refs.successModal.show();
            });

        } else {

            this.setState({ errors: errors });
            this.refs.errorModal.show();
        }


    };

    submitReport = () => {

        const reportName = this.refs.reportName.state.value;
        const reportAddress = this.refs.reportAddress.state.value;
        const reportEmail = this.refs.reportEmail.state.value;
        const reportPhone = this.refs.reportPhone.state.value;
        const details = this.refs.reportDetails.state.value;
        const hail = this.refs.reportSelect.refs.hail.state.checked;
        const wind = this.refs.reportSelect.refs.wind.state.checked;
        const rain = this.refs.reportSelect.refs.rain.state.checked;
        const other = this.refs.reportSelect.refs.other.state.checked;

        // Create Body for API Call
        const reportBody = {
            name: reportName,
            email: reportEmail,
            phoneNumber: reportPhone,
            address: reportAddress,
            hail: hail,
            wind: wind,
            rain: rain,
            other: other,
            details: details,
            requestType: 'stormReport'

        };

        let errors = [];

        // NAME
        if(reportBody.name.length === 0) {
            errors.push("Name");
        }

        // EMAIL
        const emailRegex = /\S+@\S+\.\S+/;
        if(!emailRegex.test(reportBody.email.toLowerCase())){
            errors.push("Invalid Email");
        }

        if(reportBody.address.length === 0) {
            errors.push("Address");
        }


        if(errors.length === 0) {

            const self = this;
            axios.post('https://api.theroofdocs.com/v1/inquiry', reportBody)
                .then(function (response) {

                    // Show Modal
                    self.refs.successModal.show();

                    // Clear Text Fields
                    self.clearReportFields();

                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {

            this.setState({ errors: errors});
            this.refs.errorModal.show();
        }

    };

    clearInspectionFields() {

        this.refs.inspectionName.setState({ value: '' });
        this.refs.inspectionAddress.setState({ address: '' });
        this.refs.inspectionEmail.setState({ value: '' });
        this.refs.inspectionPhone.setState({ value: '' });
        this.refs.inspectionDetails.setState({ value: '' });
        this.refs.inspectionSelect.refs.roof.setState({ checked: false });
        this.refs.inspectionSelect.refs.siding.setState({ checked: false });
        this.refs.inspectionSelect.refs.gutters.setState({ checked: false });
        this.refs.inspectionSelect.refs.damage.setState({ checked: false });
        this.refs.referralMethod.setState({ value: null, refferal: null});
        this.refs.appointmentTime.setState({ repEmail: null, selectedDate: null, availableTimes: [], selectedTime: null });

    }

    clearReportFields() {

        this.refs.reportName.setState({ value: '' });
        this.refs.reportAddress.setState({ value: '' });
        this.refs.reportEmail.setState({ value: '' });
        this.refs.reportPhone.setState({ value: '' });
        this.refs.reportDetails.setState({ value: '' });
        this.refs.reportSelect.refs.hail.setState({ checked: false });
        this.refs.reportSelect.refs.wind.setState({ checked: false });
        this.refs.reportSelect.refs.rain.setState({ checked: false });
        this.refs.reportSelect.refs.other.setState({ checked: false });
    }
}

export default EstimateExternal;