import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Styling
import '../../../style/components/blog/articleTemplate.scss';

import BANNER_ICON_UP from "../../../resources/images/misc/plus_icon_up.png";
// import TitleImage
//     from "../../../resources/images/blogs/getting-a-new-roof/what-to-expect-when-getting-a-new-roof.jpeg";
// import HowLong from '../../../resources/images/blogs/getting-a-new-roof/how-long-does-a-roof-replacement-take.jpeg'
// import Inspection from '../../../resources/images/blogs/getting-a-new-roof/CTA-get-a-free-roof-inspection-from-roof-er.jpeg'

class GettingANewRoof extends Component {

    constructor(props) {
        super(props);

        this.state = { };
    }



    render() {
        return (
            <div className="blog-template-container">
                <div className="blog-template-banner">
                    <div className="blog-template-text-container">
                        <p className="banner-text-small"> Blog </p>
                        <p className="banner-text-large"> Learn More About Roofing From Roof-ER </p>
                        <Helmet>
                            <title>What to Expect When Getting a New Roof | Roof-ER</title>
                            <meta name="description" content="A roof replacement is a big home improvement, and you want to ensure you find reliable contractors. Read what to expect when getting a new roof from Roof Docs."/>
                        </Helmet>
                    </div>
                    <img className="banner-icon" src={BANNER_ICON_UP} alt="Banner Icon Up" />
                </div>
                <div className="blog-template-content-container">
                    <div className="blog-template-content" >
                        <div className="blog-item-container">
                            <div className="blog-item-info-container">
                                <a href="/blog"><button className="blog-home-button"><span className="arrow-left"> Back to Articles </span> </button></a>
                                {/*<img className="long-form-image" src={TitleImage} alt="Title" />*/}
                                <h1>What to Expect When Getting a New Roof</h1>
                                <p>Replacing a roof is something every homeowner will likely experience at least once. Although there are numerous roofing style, structure and material options, the process for a roof replacement will typically look the same. Understanding the process can help ensure everything goes smoothly and your home remains protected from weather elements, plant life and moisture. Here is what to know before replacing your roof.&nbsp;</p>
                                <h2>How to Know If You Need a New Roof</h2>
                                <p>If you’ve lived in your home for decades or notice some signs of wear, it may be time to replace your roof.&nbsp;</p>
                                <p>A few signs you need a new roof include:&nbsp;</p>
                                <ul>
                                    <li><strong>Beams of light:</strong>&nbsp;You should not see any rays of natural light coming through the ceiling when you go into your attic. Additionally, stains or streaks indicate a leaky roof.&nbsp;</li>
                                    <li><strong>Uprooted shingles:</strong>&nbsp;Damaged shingles are an indication you need a new roof. Examine your roof for cracks, buckling or dislodged shingle granules.&nbsp;</li>
                                    <li><strong>Plant life:</strong>&nbsp;Moisture in your roof can cause plants, such as moss or mold, to inhabit the top of your home. You can use a stiff brush to remove some of these, but you should ensure there are no underlying issues causing them.&nbsp;</li>
                                    <li><strong>Extended lifetime:</strong>&nbsp;A roof can typically last about 20 years or more. Depending on the roofing material, yours may last longer, but you should expect to replace your roof around this time.&nbsp;</li>
                                    <li><strong>Sagging or drooping:</strong>&nbsp;A sagging roof could be the result of trapped, excess moisture where your roof is dipping. You should replace a sagging roof immediately to avoid additional home damage.&nbsp;</li>
                                </ul>
                                {/*<img className="long-form-image" src={HowLong} alt="How Long" />*/}
                                <h2>How Long Does a Roof Replacement Take?</h2>
                                <p>The time a roof takes to install is based on a number of factors. While many roofs can be replaced within one or two days, complexity, weather and accessibility can impede the process and make it take longer. If your roof has many sides, angles, peaks or valleys, it will take longer to replace it. Fences and landscaping can also make it challenging for roofing contractors to access your roof, leading to extended installation.&nbsp;</p>
                                <p>Seasonal weather conditions can also affect your timeline. Most contractors work only in the daylight, so seasons with longer working hours, like summer, tend to be the most efficient. Although contractors &nbsp;<a href="/blog/best-time" target="_blank" rel="noopener noreferrer">work throughout the year</a>, inclement weather conditions, such as heavy rain or snowfall, will likely result in a work delay.&nbsp;</p>
                                <p>To begin the replacement process, you need to request an inspection. Roof-ER &nbsp;<a href="/inspection" target="_blank" rel="noopener noreferrer">offers free roof inspections</a>and will diagnose the problem with your roof. If the problem is storm damage, you'll need to file a claim with your home insurance company. Otherwise, once a suitable solution has been discovered, you can begin discussing color, product and upgrade options with your contractor and prepare for the installation.&nbsp;</p>
                                <p>On installation day, you can expect the following:&nbsp;</p>
                                <ul>
                                    <li>Roofing materials will arrive at your home.&nbsp;</li>
                                    <li>You will need to move your vehicles out of the way. &nbsp;</li>
                                    <li>Contractors will set up protection, such as tarps, around your home.&nbsp;</li>
                                    <li>The roofing crew will tear off the old roof and clean the gutters. &nbsp;</li>
                                    <li>The contractors will begin installing the new roof.&nbsp;</li>
                                    <li>The contractors will take down the tarps and  &nbsp;<a href="/blog/roofing-clean-up" target="_blank" rel="noopener noreferrer">begin the cleaning process</a>&nbsp;</li>
                                </ul>
                                <p>After installing a new roof, a contractor will examine the work to ensure they’ve delivered you the high-quality results you expect. They will also typically reexamine areas that are prone to leaks to ensure there is enough protection.</p>
                                <h2>Choosing Your Roof Specifications</h2>
                                <p>When contemplating this home improvement project, consider the options that will best coordinate with your home's exterior while providing you with long-lasting protection and durability.</p>
                                <h3>Roofing Materials</h3>
                                <p>Homeowners have a large variety of roofing material options to choose from for their roof replacements. Each has a unique set of characteristics that can bring a personal touch to your home. Some materials are heavy but provide long-lasting durability, while others are lighter and offer many styles and color options.</p>
                                <p>Some popular roofing materials include:</p>
                                <ul>
                                    <li>Asphalt&nbsp;</li>
                                    <li>Metal&nbsp;</li>
                                    <li>Clay&nbsp;</li>
                                    <li>Wood&nbsp;</li>
                                    <li>Rubber&nbsp;</li>
                                    <li>Slate&nbsp;</li>
                                    <li>Tar and gravel&nbsp;</li>
                                    <li>Green roof&nbsp;</li>
                                </ul>
                                <h3>Styles and Structures</h3>
                                <p>On top of choosing your roofing material, you will need to consider the style and structure of your home. The two popular styles are shingles and tile, which have their own advantages and downsides.</p>
                                <p>Asphalt shingles are more water-resistant but are not made of environmentally-friendly materials. However, due to asphalt’s ability to reflect heat, you can save money on your energy bills in the summer. Tile roofs can create interesting and unique designs, and you can reuse them when you decide to replace your roof.</p>                      <p>Asphalt shingles weigh less than tile roof options, but they don't last as long. They are not resistant to the wind like wood shake shingles. Tile roofs can last a lifetime, but the materials can be quite heavy. A clay or metal roof can also help maintain temperatures and leaks are rare. Due to the benefits of tile roofs, they tend to be more expensive than asphalt shingles.</p>
                                <p>You will also want to consider your color and style options. Asphalt shingles come in a variety of colors and can create an even, cohesive look that coordinates with the rest of your home. You can use different shades to create appealing contrasts that attract the eye. Alternatively, tile roofs offer a plethora of style options but don’t have much color variation.</p>
                                <h2>Cost of a New Roof</h2>
                                <p>The national average cost for a roof replacement&nbsp;<a href="https://www.bobvila.com/articles/roof-replacement-cost/" target="_blank" rel="noopener noreferrer">is just over $11,500</a>, though your specific project could cost less or more than that. The size of your roof, roofing materials, the complexity of the design and additional labor fees can all affect the total cost.</p>
                                <p>With Roof-ER, you only need to pay a deductible if your claim is approved for a roof replacement. Your homeowner's insurance&nbsp;<a href="/blog/damage-covered" target="_blank" rel="noopener noreferrer">should cover hail and wind damage</a> or additional damage from storms. Other upgrades and additional labor fees will increase your total cost.</p>
                                {/*<a href="/inspection"><img className="long-form-image" src={Inspection} alt="Inspection" /></a>*/}
                                <h2>Get a Free Roof Inspection From Roof-ER</h2>
                                <p>Roof-ER is your premier roofing company in Maryland and Northern Virginia. We are dedicated to delivering quality craftsmanship, exceptional communication and outstanding results to our clients. We uphold our values of integrity, quality, simplicity throughout every project we tackle.&nbsp;</p>
                                <p>We know great businesses are built on trust, so we communicate with our clients and ensure they know exactly what they are getting. We are honest about our process and how we work because we want you to know you are putting your home in the most capable hands.&nbsp;</p>
                                <p>With Roof-ER, you can expect a fair price and quality installation every time.&nbsp;<a href="/inspection" target="_blank" rel="noopener noreferrer">Request a free inspection</a>and allow us to restore your roof to its former glory.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GettingANewRoof;
